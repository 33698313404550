import Vue from "vue";
import Vuex from "vuex";
import persistStore from 'vuex-persistedstate';

import auth from './auth';
import general from './general';
import businessRegistration from './businessRegistration';
import items from './items';
import transactions from './transactions';
import notification from './notification';
import invoiceCreate from './invoiceCreate';
import profileSettings from './profileSettings';
import storeTempValues from "./storeTempValues";


Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    general,
    businessRegistration,
    items,
    transactions,
    notification,
    invoiceCreate,
    profileSettings,
    storeTempValues
  },
  plugins: [persistStore({
    paths: ['auth', 'invoiceCreate'],
  })]
});
