import axios from "axios";

let state = {
  id: null,
  name: null, // businessname
  type: null, // businessIndustry
  bic: null,
  gst: null,
  ird: null,
  nzbn: null,
  business_type: null, // businessIndustry
  buisness_status: null, // businessDescription
  current_managing_method: null, // manageAccounts
  current_accountant_status: null, // haveAccountant
  online_payments: null, // paymentsOnline
  country: null, // businessCountry
  currency: null,
  language: null,
  time_zone: null,
  fiscal_year: null,
  customers_range: null, // numOfCustomers
  address: {
    _id: null,
    number: null,
    street: null,
    city: null,
    zipcode: null,
    province: null,
    country: null,
  },
  phone: null,
  mobile: null,
  email: null,
  website: null,
  package: null,
  vat_label: null,
};



const mutations = {
  SET_ID(state, id) {
    state.id = id;
  },
  SET_NAME(state, name) {
    state.name = name;
  },
  SET_TYPE(state, type) {
    state.type = type;
  },
  SET_BIC(state, bic) {
    state.bic = bic;
  },
  SET_GST(state, gst) {
    state.gst = gst;
  },
  SET_IRD(state, ird) {
    state.ird = ird;
  },
  SET_NZBN(state, nzbn) {
    state.nzbn = nzbn;
  },
  SET_BUSINESS_TYPE(state, business_type) {
    state.business_type = business_type;
  },
  SET_BUSINESS_STATUS(state, buisness_status) {
    state.buisness_status = buisness_status;
  },
  SET_CURRENT_MANAGING_METHOD(state, current_managing_method) {
    state.current_managing_method = current_managing_method;
  },
  SET_CURRENT_ACCOUNTANT_STATUS(state, current_accountant_status) {
    state.current_accountant_status = current_accountant_status;
  },
  SET_ONLINE_PAYMENTS(state, online_payments) {
    state.online_payments = online_payments;
  },
  SET_COUNTRY(state, country) {
    state.country = country;
  },
  SET_CURRENCY(state, currency) {
    state.currency = currency;
  },
  SET_LANGUAGE(state, language) {
    state.language = language;
  },
  SET_TIME_ZONE(state, time_zone) {
    state.time_zone = time_zone;
  },
  SET_FISCAL_YEAR(state, fiscal_year) {
    state.fiscal_year = fiscal_year;
  },
  SET_CUSTOMER_RANGE(state, customers_range) {
    state.customers_range = customers_range;
  },
  SET_NUMBER(state, number) {
    state.address.number = number;
  },
  SET_STREET(state, street) {
    state.address.street = street;
  },
  SET_CITY(state, city) {
    state.address.city = city;
  },
  SET_ZIPCODE(state, zipcode) {
    state.address.zipcode = zipcode;
  },
  SET_PROVINCE(state, province) {
    state.address.province = province;
  },
  SET_AD_COUNTRY(state, country) {
    state.address.country = country;
  },
  SET_PHONE(state, phone) {
    state.phone = phone;
  },
  SET_MOBILE(state, mobile) {
    state.mobile = mobile;
  },
  SET_EMAIL(state, email) {
    state.email = email;
  },
  SET_WEBSITE(state, website) {
    state.website = website;
  },
  SET_PACKAGE(state, pack) {
    state.package = pack;
  },
  SET_VAT_LABEL(state, label) {
    state.vat_label = label;
  },
  SET_ALL() {},
};

const actions = {
  set_id({ commit }, id) {
    commit("SET_ID", id);
  },
  set_name({ commit }, name) {
    commit("SET_NAME", name);
  },
  set_type({ commit }, type) {
    commit("SET_TYPE", type);
  },
  set_bic({ commit }, bic) {
    commit("SET_BIC", bic);
  },
  set_gst({ commit }, gst) {
    commit("SET_GST", gst);
  },
  set_ird({ commit }, ird) {
    commit("SET_IRD", ird);
  },
  set_nzbn({ commit }, nzbn) {
    commit("SET_NZBN", nzbn);
  },
  set_business_type({ commit }, business_type) {
    commit("SET_BUSINESS_TYPE", business_type);
  },
  set_buisness_status({ commit }, buisness_status) {
    commit("SET_BUSINESS_STATUS", buisness_status);
  },
  set_current_managing_method({ commit }, current_managing_method) {
    commit("SET_CURRENT_MANAGING_METHOD", current_managing_method);
  },
  set_current_accountant_status({ commit }, current_accountant_status) {
    commit("SET_CURRENT_ACCOUNTANT_STATUS", current_accountant_status);
  },
  set_online_payments({ commit }, online_payments) {
    commit("SET_ONLINE_PAYMENTS", online_payments);
  },
  set_country({ commit }, country) {
    commit("SET_COUNTRY", country);
  },
  set_currency({ commit }, currency) {
    commit("SET_CURRENCY", currency);
  },
  set_language({ commit }, language) {
    commit("SET_LANGUAGE", language);
  },
  set_time_zone({ commit }, time_zone) {
    commit("SET_TIME_ZONE", time_zone);
  },
  set_fiscal_year({ commit }, fiscal_year) {
    commit("SET_FISCAL_YEAR", fiscal_year);
  },
  set_customers_range({ commit }, customers_range) {
    commit("SET_CUSTOMER_RANGE", customers_range);
  },
  set_number({ commit }, number) {
    commit("SET_NUMBER", number);
  },
  set_street({ commit }, street) {
    commit("SET_STREET", street);
  },
  set_city({ commit }, city) {
    commit("SET_CITY", city);
  },
  set_zipcode({ commit }, zipcode) {
    commit("SET_ZIPCODE", zipcode);
  },
  set_province({ commit }, province) {
    commit("SET_PROVINCE", province);
  },
  set_ad_country({ commit }, country) {
    commit("SET_AD_COUNTRY", country);
  },
  set_mobile({ commit }, mobile) {
    commit("SET_MOBILE", mobile);
  },
  set_phone({ commit }, phone) {
    commit("SET_PHONE", phone);
  },
  set_email({ commit }, email) {
    commit("SET_EMAIL", email);
  },
  set_website({ commit }, website) {
    commit("SET_WEBSITE", website);
  },
  set_package({ commit }, pack) {
    commit("SET_PACKAGE", pack);
  },
  Set_vat_label({ commit }, label) {
    commit("SET_VAT_LABEL", label);
  },
  set_all({ commit }, payload) {
    // commit("SET_ID", payload.id);
    commit("SET_NAME", payload.name);
    commit("SET_TYPE", payload.type);
    commit("SET_BIC", payload.bic);
    commit("SET_GST", payload.gst);
    commit("SET_IRD", payload.ird);
    commit("SET_NZBN", payload.nzbn);
    commit("SET_BUSINESS_TYPE", payload.business_type);
    commit("SET_BUSINESS_STATUS", payload.buisness_status);
    commit("SET_CURRENT_MANAGING_METHOD", payload.current_managing_method);
    commit("SET_CURRENT_ACCOUNTANT_STATUS", payload.current_accountant_status);
    commit("SET_ONLINE_PAYMENTS", payload.online_payments);
    commit("SET_COUNTRY", payload.country);
    commit("SET_CURRENCY", payload.currency);
    commit("SET_LANGUAGE", payload.language);
    commit("SET_TIME_ZONE", payload.time_zone);
    commit("SET_FISCAL_YEAR", payload.fiscal_year);
    commit("SET_NUMBER", payload.address.number);
    commit("SET_STREET", payload.address.street);
    commit("SET_CITY", payload.address.city);
    commit("SET_ZIPCODE", payload.address.zipcode);
    commit("SET_PROVINCE", payload.address.province);
    commit("SET_AD_COUNTRY", payload.address.country);
    commit("SET_PHONE", payload.phone);
    commit("SET_MOBILE", payload.mobile);
    commit("SET_EMAIL", payload.email);
    commit("SET_WEBSITE", payload.website);
    commit("SET_PACKAGE", payload.package);
    commit("SET_VAT_LABEL", payload.vat_label);
  },
  async saveToLocalStorage(){
    // const business = {
    //   name: state.name,
    //   type: state.type,
    //   bic: state.bic,
    //   gst: state.gst,
    //   ird: state.ird,
    //   nzbn: state.nzbn,
    //   business_type: state.business_type,
    //   buisness_status: state.buisness_status,
    //   current_managing_method: state.current_managing_method,
    //   current_accountant_status: state.current_accountant_status,
    //   online_payments: state.online_payments,
    //   country: state.country,
    //   currency: state.currency,
    //   language: state.language,
    //   time_zone: state.time_zone,
    //   fiscal_year: state.fiscal_year,
    //   customers_range: state.customers_range,
    //   address: {
    //     // _id: "60ffef45585bb4169849d294",
    //     number: state.address.number,
    //     street: state.address.street,
    //     city: state.address.city,
    //     zipcode: state.address.zipcode,
    //     province: state.address.province,
    //     country: state.address.country,
    //   },
    //   phone: state.phone,
    //   mobile: state.mobile,
    //   email: state.email,
    //   website: state.website,
    //   package: state.package,
    //   vat_label: state.vatLabel,
    // };
    // localStorage.setItem('business_details',JSON.stringify(business));
  },
  async registerBusiness({ commit, state }) {
    const response = await axios.post("/register/business", state);
    console.log("Business Registration", response);
    console.log("State", state);
    commit("SET_ID", response.data.business._id);
  },
};

const getters = {
  getBusinessId: (state) => state.id,
  getCountry: (state) => state.country,
  getCurrency: (state) => state.currency,
  getEmail: (state) => state.email,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
